export const HUNT_REGISTER = 'HUNT_REGISTER';
export const HUNT_REGISTER_SUCCESS = 'HUNT_REGISTER_SUCCESS';
export const HUNT_REGISTER_ERROR = 'HUNT_REGISTER_ERROR';

export const HUNT_UNREGISTER = 'HUNT_UNREGISTER';
export const HUNT_UNREGISTER_SUCCESS = 'HUNT_UNREGISTER_SUCCESS';
export const HUNT_UNREGISTER_ERROR = 'HUNT_UNREGISTER_ERROR';

export const HUNT_FETCH = 'HUNT_FETCH';
export const HUNT_FETCH_SUCCESS = 'HUNT_FETCH_SUCCESS';
export const HUNT_FETCH_ERROR = 'HUNT_FETCH_ERROR';
